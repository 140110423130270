.nav-item {
  list-style-type: none;
}

.sidebar-sticky {
  top: 4rem;
  position: sticky;
  z-index: 999;
}

.gant-footer {
  bottom: 0;
  position: sticky;
}

.popup-wrapper {
  width: 15rem;
}

.popup-wrapper p {
  margin: 0.2rem;
}

.popup-wrapper ul {
  padding: 0.3rem;
  margin: 0.1rem;
}

.popup-wrapper li {
  list-style: none;
}

#loading {
  display: table;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.8;
}

#loading .loadingMsg {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding-top: 140px;
  background: url("/static/img/gif-load.gif") center center no-repeat;
}


.bg-pale-grey {
  background-color: #ECEFF1;
}

.bg-pale-cyan-l1 {
  background-color: #B2EBF2;
}

.bg-pale-cyan-l2 {
  background-color: #E0F7FA;
}

.detail-page-dl {
  color: #757575;
  font-weight: bold;
}

.detail-page-dt {
  padding: 0.7rem 0rem 0rem 1rem;
}

.detail-page-dd {
  padding: 0.7rem 0rem 0rem 1rem;
  margin: 0rem;
}